import { createSelector } from 'reselect'

import {
  FETCH_SAMPLES_REQUEST,
  FETCH_SAMPLES_SUCCESS,
  FETCH_SAMPLES_FAILURE,
  FETCH_SAMPLES_FOR_ACCT_SUCCESS,
  FETCH_SAMPLES_FOR_ACCT_CANCELED,
  SELECT_SAMPLE_FOR_DETAIL,
  SET_FILTERED_IDS,
  DELETE_SAMPLE_REQUEST,
  DELETE_SAMPLE_SUCCESS,
  DELETE_SAMPLE_FAILURE,
} from './sample-actions'
import {
  GET_ORDERS_SUCCESS
} from '../../common/actions-reducers/orders'
import { getAccountsByEmailFuzzy } from '../../common/actions-reducers/user-search-reducer'
import { createDeepSelector } from '../../common/utils/functionHelpers'
import { normalizeText } from  '../../common/utils/stringAndDateHelpers'

function reduxPageNormalizer(state, collection, page = {}) {
  // takes a collection of e.g. samples and inserts them into the normalized shape of the redux store, byId and allIds
  let { byId, byAcctId, allIds, pages } = { ...state }
  if (page !== {}) {
    pages[page['page']] = {isFetching: false, Ids: []}
  }
  collection.forEach(c => {
    if (!(c.account_id in byAcctId)) {
      byAcctId[c.account_id] = [c]
    } else {
      byAcctId[c.account_id].push(c)
    }
    allIds.push(c.id)
    byId[c.id] = c
    if (page !== {}) {
      pages[page['page']].Ids.push(c.id)
    }
  })
  return { byId, byAcctId, allIds, pages }
}

const initialState = {
  isFetching: false,
  isPosting: false,
  pendingAccounts: {},
  selectedSampleId: null,
  byId: {},
  byAcctId: {},
  allIds: [],
  filteredIds: [],
  currentPage: null,
  lastPage: false,
  pages: {}
}

export default function sampleSearchReducer(state = {...initialState}, action) {
  switch(action.type) {

    ////////////////////////
    // Get Handlers
    ////////////////////////
    case FETCH_SAMPLES_REQUEST: {
      const pendingAccounts = { ...state.pendingAccounts }
      if (action.acctId) {
        pendingAccounts[action.acctId] = true
      }
      return {...state, isFetching: true, pendingAccounts}
    }
    case GET_ORDERS_SUCCESS:
    case FETCH_SAMPLES_SUCCESS: {
      const samples = action.samples || action.data?.[0]?.samples || []
      let norm = reduxPageNormalizer(state, samples, action.page)
      return {
        ...state,
        isFetching: typeof action.page === 'undefined' ? false : !!action.page.next_url,
        byId: norm.byId,
        byAcctId: norm.byAcctId,
        allIds: norm.allIds,
        pages: typeof action.page === 'undefined' ? {} : norm.pages,
        lastPage: typeof action.page === 'undefined' ? true : !action.page.next_url,
        currentPage: action.page?.page || null,
        filteredIds: samples.map(s => s.id) || []
      }
    }
    case FETCH_SAMPLES_FOR_ACCT_SUCCESS: {
      let norm = reduxPageNormalizer(state, action.samples)
      return {
        ...state,
        isFetching: false,
        byId: norm.byId,
        allIds: norm.allIds,
        byAcctId: {...norm.byAcctId, [action.acctId]: action.samples},
        lastPage: true,
        pendingAccounts: {}
      }
    }
    case FETCH_SAMPLES_FOR_ACCT_CANCELED: {
      return {...state, isFetching: false, pendingAccounts: {}}
    }
    case FETCH_SAMPLES_FAILURE: {
      return {...state, isFetching: false, error: action.error}
    }

    case SELECT_SAMPLE_FOR_DETAIL: {
      const samples = Object.values(state.byId)
      if(action.tubeId) {
        var selectedSample = samples.find(s => s.tube_id === action.tubeId)
        var selectedSampleId = selectedSample ? selectedSample.id : null
        return {...state, selectedSampleId: selectedSampleId}
      }
      else return {...state, selectedSampleId: null}
    }
    case SET_FILTERED_IDS: {
      return {...state, filteredIds: action.ids}
    }

    ////////////////////////
    // Delete Handlers
    ////////////////////////
    case DELETE_SAMPLE_REQUEST: {
      return { ...state}
    }
    case DELETE_SAMPLE_SUCCESS: {
      const { sampleId } = action
      if (state.allIds.find(item => item === sampleId)) {
        delete state.byId[sampleId]
        const newFiltered = state.filteredIds.filter(id => id !== sampleId)
        const newById = Object.assign({}, state.byId)
        return {
          ...state,
          allIds: state.allIds.filter(item => item !== sampleId),
          byId: newById,
          selectedSampleId: null,
          filteredIds: newFiltered
        }
      } else {
        return {
          ...state
        }
      }
    }

    case DELETE_SAMPLE_FAILURE: {
      let msgString = null
      switch (action.error.status) {
        case 401:
          msgString = '401'
          break
        case 400:
          msgString = '400'
          msgString = action.error.data
          break
        case 409:
          msgString = action.error.data
          break
        default:
          msgString = ''
          break
      }
      return { ...state, errorMessage: msgString }
    }
    default: {
      return state
    }
  }
}

export function getIsAllSamples(store) {
  // with query params in `fetchSamples`, the best way to tell if it is ALL samples
  // would be with a new endpoint to fetch the total number of samples and to compare
  // against that. Too much work ATM, so proceeding with logic here that will break easily
  // should any new requests fetch a subset of samples. In that future case, this will
  // return true when it should return false. Must come up with something better...
  return !!store.entities.samples.lastPage
}

export function getSamplesByTubeIdFuzzy(store, targetTubeId) {
  const samples = Object.values(store.entities.samples.byId)
  return samples.filter(
    sample => sample.tube_id.toLowerCase().includes(normalizeText(targetTubeId))
  )
}

export function getSampleByTube(store, targetTubeId){
  const samples = Object.values(store.entities.samples.byId)
  return samples.find(sample=> sample.tube_id === targetTubeId)
}


export function getSamplesByOrderCodeFuzzy(store, targetId) {
  const samples = Object.values(store.entities.samples.byId)
  return samples.filter(
    sample => sample.order_code && sample.order_code.includes(normalizeText(targetId))
  )
}
export function getSamplesByEmailFuzzy(store, targetEmail) {
  const samples = Object.values(store.entities.samples.byId)
  const accountIds = getAccountsByEmailFuzzy(store, targetEmail)
  return samples.filter(sample => {
    return accountIds.includes(sample.account_id)
  })
}

export const getCrops = createSelector(
  store => store.entities.crops.crops,
  crops => crops
)

export const getAllUsersById = createSelector(
  store => store.entities.users.allIds,
  allUsersById => allUsersById
)

export const getSelectedSample = (store) => (
  store.entities.samples.selectedSampleId  &&
  store.entities.samples.byId[store.entities.samples.selectedSampleId]
)

export const makeGetSamplesByIdWithUser = ()=>createDeepSelector(
  [
    (store, props) => props.sampleIds ? props.sampleIds.reduce((memo, id) => {
      memo[id] = store.entities.samples.byId[id]
      return memo
    }, {} ) : store.entities.samples.byId,
    store=>store.entities.users.byId
  ],
  (samplesById, usersById) => {
    let samples = Object.values(samplesById)
    let users = Object.values(usersById)
    return (
      !users.length ? [] :
        samples.reduce((memo, sample) => {
          if (sample) {
            var user = users.find(u => u.account_id === sample.account_id)
            return memo.concat({...user, ...sample })
          } else {
            return memo
          }
        }, [])
    )
  })
