import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { StyledFlashMessage } from './styles';
import { removeFlashMessage } from './messages-actions';


class FlashMessages extends Component {
  constructor(props) {
    super(props)
 
    this.renderMessage = this.renderMessage.bind(this)
  }

  

  renderMessage(message) {
    
    return (
          <div
            key={ message.id }
            role="alert"
            className={ `flash-message alert alert-${message.type} `}
            onClick={ () => this.props.removeFlashMessage(message.id) }
          >
               
            {message.title &&
              <strong>{ message.title }</strong>
            }
            &nbsp;-
            { message.text }

            {message.text2 && 
              <span>
                <br/>
                { message.text2 }
              </span>
            }
            <button type="button" className="close"  aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
    );
  }

  render() {
    const messages = this.props.messages;
    return (
      <StyledFlashMessage>
        { messages.map((message) => this.renderMessage(message))}
      </StyledFlashMessage>
    );
  }
}

FlashMessages.propTypes = {
  messages: PropTypes.array,
  removeFlashMessage: PropTypes.func
};

const mapStateToProps = ({ messagesReducer }) => {
  return {
    messages: messagesReducer.messages
  }
}

export default connect(
  mapStateToProps,
  { removeFlashMessage })(FlashMessages);
