import React from 'react';
import styled, {css} from 'styled-components';
const loader = require('../../assets/images/loader.svg');


const StyledLoadingWrapper = styled.div`
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props=>props.height && css`
    height: ${props.height};
    img {
     height: 50%;
  }`}
  ${props=>props.display && css`
    display: ${props.display};
  `}
`;

//if passed a height prop, scale appropriately
export const Loader = ({height, display})=>(
  <StyledLoadingWrapper height={height} display={display}>
    <img src={loader} />
  </StyledLoadingWrapper>
);

export default Loader;
