
import { addFlashMessage } from '../../components/FlashMessages/messages-actions';
import { patchBaseApiHelper, postBaseAuthHelper, getBaseApiHelper }  from '../utils/apiHelpers';
import { push } from 'react-router-redux';

// Action Types
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';

// Action Creators
function loginRequest(creds) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false
  };
}

function loginSuccess(token, user) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    authToken: token,
    user
  };
}

function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  };
}

function logoutRequest() {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: false
  };
}

function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false
  };
}

function registerRequest() {
  return {
    type: REGISTER_REQUEST,
    isFetching: true,
    isAuthenticated: false,
  };
}
function registerSuccess(){
  return {
    type: REGISTER_SUCCESS,
    isFetching: false,
    isAuthenticated: false
  };
}

function registerError(message) {
  return {
    type: REGISTER_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  };
}

function resetPasswordRequest() {
  return {
    type: RESET_PASSWORD_REQUEST,
    isFetching: true
  };
}

function resetPasswordSuccess() {
  return {
    type: RESET_PASSWORD_SUCCESS,
    isFetching: false
  };
}

function resetPasswordError(error) {
  return {
    type: RESET_PASSWORD_FAILURE,
    isFetching: false,
    error
  };
}

function updatePasswordRequest() {
  return {
    type: UPDATE_PASSWORD_REQUEST,
    isFetching: true
  };
}

function updatePasswordSuccess() {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    isFetching: false
  };
}

function updatePasswordError(error) {
  return {
    type: UPDATE_PASSWORD_FAILURE,
    isFetching: false,
    error
  };
}

function userRequest() {
  return {
    type: USER_REQUEST,
    isFetching: true,
  };
}

function userSuccess(user) {
  return {
    type: USER_SUCCESS,
    isFetching: false,
    user
  };
}

function userError(error) {
  return {
    type: USER_FAILURE,
    isFetching: false,
    error,
  };
}

function userUpdateRequest() {
  return {
    type: USER_UPDATE_REQUEST,
    isFetching: true
  };
}

function userUpdateSuccess() {
  return {
    type: USER_UPDATE_SUCCESS,
    isFetching: false
  };
}

function userUpdateError(error) {
  return {
    type: USER_UPDATE_FAILURE,
    isFetching: false,
    error
  };
}

// Thunks/Async Action Creators
export function getUser(userId = null) {
  userId = userId ? userId : JSON.parse(sessionStorage.getItem('userId'));
  return dispatch => {
    dispatch(userRequest());
    getBaseApiHelper(`/users/${userId}`)
      .then(response => {
        window.sessionStorage.setItem('userId', JSON.stringify(response.data.id));
        dispatch(userSuccess(response.data));
      }).catch(error => {
        dispatch(userError(error.response.data));
        return error.response.data;
      });
  };
}

export function loginAdmin(creds) {
  /* type(creds) = Object */
  return dispatch => {
    dispatch(loginRequest(creds));
    return postBaseAuthHelper('/login_admin', creds)
      .then(response => {
        window.sessionStorage.setItem('authToken', response.data.auth_token);
        window.sessionStorage.setItem('accountId', JSON.stringify(response.data.user.account_id));
        window.sessionStorage.setItem('userId', JSON.stringify(response.data.user.id));

        dispatch(loginSuccess(response.data.auth_token, response.data.user));
        // update auth reducer with current user and associated flags
        dispatch(getUser());
        return response;
      }).catch(error => {
        dispatch(loginError(error.response.data.message));
        return error.response;
      });
  };
}

export function logoutUser() {
  return dispatch => {
    dispatch(logoutRequest());
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('accountId');
    sessionStorage.removeItem('userId');
    dispatch(push('/login'));
    dispatch(logoutSuccess());
  };
}

export function registerUser(data) {
  /* type(data) = Object */
  return dispatch => {
    dispatch(registerRequest());
    return postBaseAuthHelper('/register', data)
      .then(response => {
        dispatch(registerSuccess());
        dispatch(addFlashMessage({
          type: 'success',
          title: 'Welcome! ',
          text: ' You have successfully signed up for Trace Genomics!',
          text2: 'Check your email for confirmation.'
        }));
      }).catch(error => {
        dispatch(registerError(error.response.data.message));

        return error.response.data;
      });
  };
}

export function resetPassword(creds) {
  /* type(creds) = Object */
  return dispatch => {
    dispatch(resetPasswordRequest());
    return postBaseAuthHelper('/reset_password', {email: creds.email})
      .then(response => {
        dispatch(resetPasswordSuccess());
        dispatch(addFlashMessage({
          type: 'success',
          title: 'Password Reset ',
          text: 'Your password has been reset.',
          text2: 'Check your email for your temporary password. '
        }));
      }).catch(error => {
        dispatch(resetPasswordError(error.response.data.message));
        dispatch(addFlashMessage({
          type: 'danger',
          title: 'Password Failed to Reset! ',
          text: 'Something went wrong.'
        }));
        return Promise.reject(error.response?.data.message)
      });
  };
}

export function updatePassword(creds) {
  /* type(userId) = str */
  return dispatch => {
    dispatch(updatePasswordRequest());
    return postBaseAuthHelper('/update_password', creds)
      .then(response => {
        dispatch(updatePasswordSuccess());
        // refetch the user after temp_password=False
        dispatch(getUser());
        dispatch(addFlashMessage({
          type: 'success',
          title: 'Password Updated! ',
          text: 'Your password has successfully been updated.'
        }));
      }).catch(error => {
        dispatch(updatePasswordError(error.response.data.message));
        if (error.response.status === 403) {
          dispatch(addFlashMessage({
            type: 'danger',
            title: 'Account Password Failed to Update! ',
            text: 'Make sure your old password was entered correctly.'
          }));
        } else {
          dispatch(addFlashMessage({
            type: 'danger',
            title: 'Account Password Failed to Update! ',
            text: 'Something went wrong.'
          }));
        }
        return error.response.data;
      });
  };
}

// Thunks/Async Action Creators
export function updateUser(user) {
  // TODO: move this out of /auth and into a new users.jsx file for /api/users endpoint

  const userId = JSON.parse(sessionStorage.getItem('userId'));
  const url = `/users/${userId}`;
  return dispatch => {
    dispatch(userUpdateRequest());
    return patchBaseApiHelper( url, user )
      .then(response => {
        dispatch(userUpdateSuccess());
        // refetch the user after temp_password=False
        dispatch(getUser());
        dispatch(addFlashMessage({
          type: 'success',
          title: 'Account Details Updated! ',
          text: ' Your information has successfully been updated.'
        }));
      }).catch(error => {
        dispatch(userUpdateError(error.response.data));
        dispatch(addFlashMessage({
          type: 'danger',
          title: 'Account Details Failed to Update! ',
          text: 'Something went wrong.'
        }));
        return error.response.data;
      });
  };
}

