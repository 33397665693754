import styled from 'styled-components';

export const StyledFlashMessage = styled.div`
	position: absolute;
	width: 100%;
	top: 80px;
	left: 0;
	display: flex;
	align-items: center;
	align-content: center;
	flex-direction: column;
	z-index: 1;
	
	.alert-success {
		color: #155724;
		background: #d4edda;
		border: 1px solid #d4edda;
	}
	.alert-error {
		color: #721c24;
		background: #f8d7da;
		border: 1px solid #f5c6cb;
	}
	.flash-message{
		margin-top: 0;
		text-align: center;
		width: 90%;
		cursor: default;
		transition: width .15s ease-in;

		.close{
			outline: none;
		}
		div{
			display: flex;
			justify-content: center;
			align-items: center;
			flex-flow: column;
		}
	}
	.icon {
		margin-right: 10px;
		line-height: 24px;
	}
	@media screen and (min-width: 768px){
		.flash-message{
			div{
				flex-flow: row;
			}
		}
	}
	
	@media screen and (min-width: 1024px){
		.flash-message{
			width: 60%;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}
`;
