import {
  getBaseApiHelper,
  patchBaseApiHelper
} from '../../common/utils/apiHelpers'

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'

export const USER_GROUPS_REQUEST = 'USER_GROUPS_REQUEST'
export const USER_GROUPS_SUCCESS = 'USER_GROUPS_SUCCESS'
export const USER_GROUPS_FAILURE = 'USER_GROUPS_FAILURE'

// Action Creators
function usersRequest() {
  return {
    type: FETCH_USERS_REQUEST,
    isFetching: true
  }
}

function usersSuccess(users) {
  return {
    type: FETCH_USERS_SUCCESS,
    users: users
  }
}

function usersFailure(users) {
  return {
    type: FETCH_USERS_FAILURE,
    users: users
  }
}

function userUpdateRequest(){
  return {
    type: UPDATE_USER_REQUEST,
    isFetching: true
  }
}
function userUpdateSuccess(user){
  return {
    type: UPDATE_USER_SUCCESS,
    user
  }
}
function userUpdateFailure(){
  return {
    type: UPDATE_USER_FAILURE,
  }
}

export function userGroupsRequest() {
  return {
    type: USER_GROUPS_REQUEST,
    isFetchingGroups: true,

  }
}

export function userGroupsSuccess(groups) {
  return {
    type: USER_GROUPS_SUCCESS,
    isFetchingGroups: false,
    groups: groups
  }
}

export function userGroupsFailure() {
  return {
    type: USER_GROUPS_FAILURE,
    isFetchingGroups: false
  }
}
export function fetchAllUsers() {
  return dispatch => {
    dispatch(usersRequest())
    return getBaseApiHelper('/users')
      .then(response => {
        dispatch(usersSuccess(response.data))
        return response.data
      })
      .catch(error => {
        dispatch(usersFailure(error))
        return error.response
      })
  }
}

export function updateUser(userId, values){
  return dispatch => {
    dispatch(userUpdateRequest())
    return patchBaseApiHelper(`/users/${userId}`, values)
      .then(response => {
        dispatch(userUpdateSuccess(response.data))
        return response.data
      })
      .catch(error => {
        dispatch(userUpdateFailure(error))
        return Promise.reject(error.response)
      })
  }
}
export function fetchUserGroups(){
  return dispatch => {
    dispatch(userGroupsRequest())
    getBaseApiHelper('/users/groups')
      .then(response => {
        dispatch(userGroupsSuccess(response.data.groups))
      }).catch(error => {
        dispatch(userGroupsFailure(error.response.data))
        return error.response.data
      })
  }
}
