import { createSelector } from 'reselect'

import { getBaseApiHelper } from '../../common/utils/apiHelpers'

// ACTIONS
const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST'
const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE'

const getProductsRequest = () => ({type: GET_PRODUCTS_REQUEST})
const getProductsSuccess = data => ({
  type: GET_PRODUCTS_SUCCESS,
  data
})
const getProductsFailure = error => ({
  type: GET_PRODUCTS_FAILURE,
  error
})

export const getProducts = () => {
  return dispatch => {
    dispatch(getProductsRequest())
    return getBaseApiHelper(`/products`).then(resp => {
      dispatch(getProductsSuccess(resp.data))
    }).catch(error => {
      dispatch(getProductsFailure(error.response))
    })
  }
}

// REDUCER
const initialState = {
  pending: false,
  error: null,
  byId: {}
}

export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_REQUEST: {
      return { ...state, pending: true, error: null }
    }
    case GET_PRODUCTS_SUCCESS: {
      return { ...state, pending: false, error: null, byId: action.data.reduce((byId, product) => {
        product.version = Number.isInteger(product.version) ? `${product.version}.0` : `${product.version}`
        product.hasBulkDensity = !!product.indicator_keys.includes('bd:s-bd-core-00')
        return Object.assign({}, byId, { [product.id]: product })
      }, { ...state.byId })}
    }
    case GET_PRODUCTS_FAILURE: {
      return { ...state, pending: false, error: action.error }
    }
    default: {
      return { ...state }
    }
  }
}


// ACTIONS
const GET_ANALYSIS_PRODUCTS_REQUEST = 'GET_ANALYSIS_PRODUCTS_REQUEST'
const GET_ANALYSIS_PRODUCTS_SUCCESS = 'GET_ANALYSIS_PRODUCTS_SUCCESS'
const GET_ANALYSIS_PRODUCTS_FAILURE = 'GET_ANALYSIS_PRODUCTS_FAILURE'

const getAnalysisProductsRequest = () => ({type: GET_PRODUCTS_REQUEST})
const getAnalysisProductsSuccess = data => ({
  type: GET_ANALYSIS_PRODUCTS_SUCCESS,
  data
})
const getAnalysisProductsFailure = error => ({
  type: GET_ANALYSIS_PRODUCTS_FAILURE,
  error
})

export const getAnalysisProducts = () => {
  return dispatch => {
    dispatch(getAnalysisProductsRequest())
    return getBaseApiHelper(`/products/analysis`).then(resp => {
      dispatch(getAnalysisProductsSuccess(resp.data))
    }).catch(error => {
      dispatch(getAnalysisProductsFailure(error.response))
    })
  }
}

// REDUCER
const initialAnalysisState = {
  pending: false,
  error: null,
  byId: {}
}

export const analysisProductsReducer = (state = initialAnalysisState, action) => {
  switch (action.type) {
    case GET_ANALYSIS_PRODUCTS_REQUEST: {
      return { ...state, pending: true, error: null }
    }
    case GET_ANALYSIS_PRODUCTS_SUCCESS: {
      return { ...state, pending: false, error: null, byId: action.data.reduce((byId, product) => {
        return Object.assign({}, byId, { [product.id]: product })
      }, { ...state.byId })}
    }
    case GET_ANALYSIS_PRODUCTS_FAILURE: {
      return { ...state, pending: false, error: action.error }
    }
    default: {
      return { ...state }
    }
  }
}



//////////////////
// SELECTORS
//////////////////
const getProductsById = state => state.entities.products.byId
export const getCurrentProducts = createSelector(
  getProductsById,
  productsById => {
    return Object.values(productsById)
      .filter(p => p.is_active)
      .sort((a, b) => a.name > b.name ? 1 : -1)}
)
const getProductOption = product => {
  let platform = ''
  if (product.platform) {
    platform = ` (platform ${product.platform})`
  }
  return {value: product.id, label: `${product.name} v${product.version}${platform}`}
}
export const getProductOptions = createSelector(
  getCurrentProducts,
  currentProducts=>currentProducts.map(getProductOption)
)

const getAnalysisProductsById = state => state.entities.analysisProducts.byId
export const getCurrentAnalysisProducts = createSelector(
  getAnalysisProductsById,
  analyisProductsById => (
    Object.values(Object.values(analyisProductsById)
      .reduce((acc, cur) => ({ ...acc, [cur.name]: acc[cur.name] && acc[cur.name].version > cur.version ? acc[cur.name] : cur }), {}))
      .sort((a, b) => a.name > b.name ? 1 : -1))
)
export const getAnalysisProductOptions = createSelector(
  getCurrentAnalysisProducts,
  currentProducts=>currentProducts.map(product => ({value: product.id, label: `${product.name} v${product.version}`}))
)
