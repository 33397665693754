import * as React from 'react'

// react admin
import {
  Admin,
  Resource,
  fetchUtils,
  Menu,
  Layout,
  // account list
  List,
  Datagrid,
  DateField,
  TextField,
  useRecordContext,
  useRedirect,
  // edit
  Edit,
  DateInput,
  // post
  Create,
  SimpleForm,
  TextInput,
  required,
  // user list
  ReferenceField,
  BooleanField,
  BooleanInput,
  EmailField,
  // account permission edit
  ReferenceInput,
  SelectInput,
  // delete
  DeleteWithConfirmButton,
  SaveButton,
  Toolbar,
  // search
  ListBase,
  FilterForm,
  CreateButton,
  ExportButton,
  Pagination
} from 'react-admin'
import { Stack } from '@mui/material'
import simpleRestProvider from 'ra-data-simple-rest'

import { BASE_ADMIN_URL } from '../../common/utils/apiHelpers'


const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const token = sessionStorage.getItem('authToken')
  options.headers.set('Authorization', `Bearer ${token}`)
  const json = fetchUtils.fetchJson(url, options)
  return json
}
const dataProvider = simpleRestProvider(BASE_ADMIN_URL, httpClient)

const AdminMenu = () => (
  <Menu sx={{ minWidth: 250 }}>
    <Menu.Item to="/search-users" primaryText="Internal Portal" />
    <Menu.Item to="/accounts" primaryText="Accounts" />
    <Menu.Item to="/account_permissions" primaryText="Account Permissions" />
    <Menu.Item to="/users" primaryText="Users" />
    <Menu.Item to="/indicators" primaryText="Indicators" />
  </Menu>
)
const AdminLayout = props => <Layout {...props} menu={AdminMenu} sx={{ '& .RaLayout-content': {marginLeft: 20} }} />

const AdminCompSciMenu = () => (
  <Menu sx={{ minWidth: 250 }}>
    <Menu.Item to="/search-users" primaryText="Internal Portal" />
    <Menu.Item to="/indicators" primaryText="Indicators" />
  </Menu>
)
const AdminCompSciLayout = props => <Layout {...props} menu={AdminCompSciMenu} sx={{ '& .RaLayout-content': {marginLeft: 20} }} />

const FilledOrNullTextInput = props => {
  return (
    <TextInput
      {...props}
      defaultValue={null}
      format={value => value === null ? '' : value}
      parse={value => value === '' ? null : value}
    />
  )
}

const EditToolbar = props => {
  return (
    <Toolbar {...props}>
      <SaveButton/>
      <DeleteWithConfirmButton
        confirmContent="You will not be able to recover this record. Are you sure?"
      />
    </Toolbar>
  )
}

const ArrayTextInput = props => {
  return (
    <TextInput
      {...props}
      defaultValue={[]}
      parse={value => value?.split(',') || []}
    />
  )
}

const IdUrlField = (props) => {
  const record = useRecordContext()
  const redirect = useRedirect()
  const handleClick = () => {
    redirect(`/${props.resourcename}/${record.id}`)
  }
  return <TextField {...props} onClick={handleClick} style={{ color: '#1976D2', cursor: 'pointer' }} />
}

const accountFilters = [
  <TextInput key="name" label="Name" source="name" alwaysOn />
]

const ListToolbar = () => (
  <Stack direction="row" spacing={2} >
    <FilterForm filters={accountFilters} />
    <CreateButton />
    <ExportButton />
  </Stack>
)

const AccountList = () => (
  <ListBase>
    <ListToolbar />
    <Datagrid bulkActionButtons={false}>
      <IdUrlField source="id" resourcename="accounts" />
      <TextField source="name" />
      <TextField source="salesforce_account_id" />
      <DateField source="created" />
      <DateField source="updated" />
    </Datagrid>
    <Pagination />
  </ListBase>
)

const AccountEdit = () => (
  <Edit>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput disabled source="id" />
      <TextInput required source="name" />
      <TextInput source="salesforce_account_id" />
      <DateInput disabled source="created" />
      <DateInput disabled source="updated" />
    </SimpleForm>
  </Edit>
)

const AccountCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <TextInput source="salesforce_account_id" validate={[required()]} label="Salesforce Account ID" />
    </SimpleForm>
  </Create>
)

const userFilters = [
  <TextInput key="email" label="Email" source="email" alwaysOn />,
  <TextInput key="firstName" label="First Name" source="first_name" alwaysOn />,
  <TextInput key="lastName" label="Last Name" source="last_name" alwaysOn />
]

const UserToolbar = () => (
  <Stack direction="row" spacing={2} >
    <FilterForm filters={userFilters} />
    <CreateButton />
    <ExportButton />
  </Stack>
)

const UserList = () => (
  <ListBase>
    <UserToolbar />
    <Datagrid bulkActionButtons={false}>
      <IdUrlField source="id" resourcename="users" />
      <EmailField source="email" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <BooleanField source="is_active" />
      <BooleanField source="admin" />
      <TextField source="user_type" />
      <ReferenceField source="account_id" reference="accounts"><TextField source="name" /></ReferenceField>
      <TextField source="business_name" />
      <TextField source="_default_product_id"/>
      <TextField source="default_analysis_product_id" />
      <TextField source="groups" />
      <TextField source="logo_path" />
      <TextField source="phone" />
      <DateField source="created" />
      <DateField source="updated" />
    </Datagrid>
    <Pagination />
  </ListBase>
)

const UserEdit = () => (
  <Edit>
    <SimpleForm toolbar={<EditToolbar />} >
      <TextInput disabled source="id" />
      <TextInput required source="email" />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <BooleanInput source="is_active" />
      <BooleanInput source="admin" />
      <TextInput required source="user_type" />
      <TextInput disabled source="account_id" />
      <TextInput source="business_name" />
      <FilledOrNullTextInput source="_default_product_id"/>
      <FilledOrNullTextInput source="default_analysis_product_id" />
      <ArrayTextInput source="groups" />
      <TextInput source="logo_path" />
      <TextInput source="phone" />
      <DateInput disabled source="created" />
      <DateInput disabled source="updated" />
    </SimpleForm>
  </Edit>
)

const UserCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="email" validate={[required()]} />
      <TextInput source="first_name" validate={[required()]} />
      <TextInput source="last_name" validate={[required()]} />
      <BooleanInput source="is_active" />
      <BooleanInput source="admin" />
      <TextInput source="user_type" validate={[required()]}/>
      <TextInput source="account_id" validate={[required()]} />
      <TextInput source="business_name" />
      <FilledOrNullTextInput source="_default_product_id" validate={[required()]} />
      <FilledOrNullTextInput source="default_analysis_product_id" />
      <ArrayTextInput source="groups" />
      <TextInput source="logo_path" />
      <TextInput source="phone" />
    </SimpleForm>
  </Create>
)

const AccountPermissionList = () => (
  <List>
    <Datagrid bulkActionButtons={false}>
      <IdUrlField source="id" resourcename="account_permissions" />
      <ReferenceField source="permissioned_account_id" reference="accounts"><TextField source="name" /></ReferenceField>
      <ReferenceField source="owning_account_id" reference="accounts"><TextField source="name" /></ReferenceField>
      <TextField source="access_type" />
      <BooleanField source="activated" />
      <DateField source="created" />
      <DateField source="updated" />
    </Datagrid>
  </List>
)

const AccountPermissionEdit = () => (
  <Edit>
    <SimpleForm toolbar={<EditToolbar />} >
      <TextInput disabled source="id" />
      <ReferenceInput source="permissioned_account_id" reference="accounts"><SelectInput disabled optionText="name" /></ReferenceInput>
      <ReferenceInput source="owning_account_id" reference="accounts"><SelectInput disabled optionText="name" /></ReferenceInput>
      <TextInput source="access_type" />
      <BooleanInput source="activated" />
      <DateInput disabled source="created" />
      <DateInput disabled source="updated" />
    </SimpleForm>
  </Edit>
)

const AccountPermissionCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="permissioned_account_id" validate={[required()]} />
      <TextInput source="owning_account_id" validate={[required()]} />
      <TextInput source="access_type" validate={[required()]} />
      <BooleanInput source="activated" />
    </SimpleForm>
  </Create>
)

const indicatorFilters = [
  <TextInput key="name" label="Name" source="name" alwaysOn />,
]

const IndicatorToolbar = () => (
  <Stack direction="row" spacing={2} >
    <FilterForm filters={indicatorFilters} />
    <CreateButton />
    <ExportButton />
  </Stack>
)

const IndicatorList = () => (
  <ListBase>
    <IndicatorToolbar />
    <Datagrid bulkActionButtons={false}>
      <IdUrlField source="id" resourcename="indicators" />
      <TextField source="name" />
      <TextField source="short_name" />
      <TextField source="units" />
      <TextField source="indicator_type" />
      <TextField source="key" />
      <TextField source="modus_test_id" />
      <TextField source="description" />
      <BooleanField source="beta" />
      <BooleanField source="deprecated" />
      <TextField source="eppo_code" />
      <BooleanField source="measured" />
      <TextField source="search_term" />
      <TextField source="sort_order" />
      <TextField source="taxonomic_group" />
      <DateField source="created" />
      <DateField source="updated" />
    </Datagrid>
    <Pagination />
  </ListBase>
)

const IndicatorUnits = () => (
  <SelectInput source="units" choices={[
    { id: "# eggs/100 cc's", name: "# eggs/100 cc's" },
    { id: '%', name: '%' },
    { id: '% aerobic', name: '% aerobic' },
    { id: '% mass', name: '% mass' },
    { id: 'PPB Soil', name: 'PPB Soil' },
    { id: 'dS/m', name: 'dS/m' },
    { id: 'g', name: 'g' },
    { id: 'g/cm^3', name: 'g/cm^3' },
    { id: 'genes / organism', name: 'genes / organism' },
    { id: 'meq/100 g', name: 'meq/100 g' },
    { id: 'ppm', name: 'ppm' },
    { id: 'ratio', name: 'ratio' }
  ]} />
)

const IndicatorType = () => (
  <SelectInput source="indicator_type" choices={[
    { id: 'CHEMISTRY', name: 'CHEMISTRY' },
    { id: 'NEMATODE', name: 'NEMATODE' },
    { id: 'PATHOGEN', name: 'PATHOGEN' },
    { id: 'PSCORE', name: 'PSCORE' },
    { id: 'SOIL_HEALTH_INDICATOR', name: 'SOIL_HEALTH_INDICATOR' }
  ]} validate={[required()]} />
)

const IndicatorCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <TextInput source="short_name" />
      <IndicatorUnits />
      <IndicatorType />
      <TextInput source="key" validate={[required()]} />
      <TextInput source="modus_test_id" />
      <TextInput source="description" />
      <BooleanInput source="beta" />
      <BooleanInput source="deprecated" />
      <TextInput source="eppo_code" />
      <BooleanInput source="measured" />
      <TextInput source="search_term" />
      <FilledOrNullTextInput source="sort_order" />
      <TextInput source="taxonomic_group" />
    </SimpleForm>
  </Create>
)

const IndicatorEdit = () => (
  <Edit>
    <SimpleForm toolbar={<EditToolbar />} >
      <TextInput disabled source="id" />
      <TextInput source="name" validate={[required()]} />
      <TextInput source="short_name" />
      <IndicatorUnits />
      <IndicatorType />
      <TextInput disabled source="key" />
      <TextInput disabled source="modus_test_id" />
      <TextInput source="description" />
      <BooleanInput source="beta" />
      <BooleanInput source="deprecated" />
      <TextInput source="eppo_code" />
      <BooleanInput source="measured" />
      <TextInput source="search_term" />
      <FilledOrNullTextInput source="sort_order" />
      <TextInput source="taxonomic_group" />
      <DateInput disabled source="created" />
      <DateInput disabled source="updated" />
    </SimpleForm>
  </Edit>
)

export const AdminContainer = props => {
  if (props?.user?.flags?.['crud-admin']) {
    return (
      <Admin dataProvider={dataProvider} layout={AdminLayout} >
        <Resource name="accounts" list={AccountList} edit={AccountEdit} create={AccountCreate} />
        <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} />
        <Resource name="account_permissions" list={AccountPermissionList} edit={AccountPermissionEdit} create={AccountPermissionCreate} />
        <Resource name="indicators" list={IndicatorList} edit={IndicatorEdit} create={IndicatorCreate} />
      </Admin>
    )
  } else if (props?.user?.flags?.['crud-admin-comp-sci']) {
    return (
      <Admin dataProvider={dataProvider} layout={AdminCompSciLayout} >
        <Resource name="indicators" list={IndicatorList} edit={IndicatorEdit} create={IndicatorCreate} />
      </Admin>
    )
  }
}
