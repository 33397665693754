import {
  getBaseApiHelper,
} from '../utils/apiHelpers';
export const GET_PERMISSIONS_REQUEST = 'GET_PERMISSIONS_REQUEST';
export const GET_PERMISSIONS_FAILURE = 'GET_PERMISSIONS_FAILURE';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';



const getPermissionsRequest = (acctId) => ({
  type: GET_PERMISSIONS_REQUEST,
  isFetching: true,
  acctId
});

const getPermissionsSuccess = (acctId, payload) => ({
  type: GET_PERMISSIONS_SUCCESS,
  payload,
  acctId
});

const getPermissionsFailure = (acctId, payloadError) => ({
  type: GET_PERMISSIONS_FAILURE,
  error: payloadError,
  acctId
});

export const getPermissions = (accountId) => {
  if (!accountId){
    return
  }
  return dispatch => {
    dispatch(getPermissionsRequest(accountId));
    return  Promise.all([
      getBaseApiHelper(`/account_permissions?owner=${accountId}`),
      getBaseApiHelper(`/account_permissions?permissioned=${accountId}`)
    ]).then(response => {
      return dispatch(getPermissionsSuccess(accountId, {
        owned: response[0].data,
        permissioned: response[1].data
      }));
    })
      .catch(rejected => {
        dispatch(getPermissionsFailure(rejected.response));
        return Promise.reject({ error: rejected.response });
      });
  };
}
