import React, {Fragment}from 'react'
import PropTypes from 'prop-types'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

import { NavLink } from 'react-router-dom'

import logo from '../../assets/images/logo@2x.png'
import { logoutUser } from '../../common/actions-reducers/auth-actions'
import { StyledNavBar } from './styles'


class NavBar extends React.Component {
  constructor() {
    super()
    this.state = {
      isOpen: false
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render() {
    const { currentUser, dispatch } = this.props

    return (
      <StyledNavBar className="app-navbar">
        <Navbar light expand="lg">
          <NavLink to="/" className="navbar-brand">
            {/* eslint-disable */}
            <img src={logo} alt="Trace Genomics Logo" />
          </NavLink>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="mr-auto" navbar >
              {this.props.isAuthenticated &&
                <Fragment>
                  <NavItem className={this.state.isOpen ? 'open' : ''}>
                    <NavLink to="/search-samples" onClick={this.toggle}>Search Samples</NavLink>
                  </NavItem>
                  <NavItem className={this.state.isOpen ? 'open' : ''}>
                    <NavLink to="/search-users" onClick={this.toggle}>Search Users</NavLink>
                  </NavItem>
                  <NavItem className={this.state.isOpen ? 'open' : ''}>
                    <NavLink to="/orders" onClick={this.toggle}>Orders</NavLink>
                  </NavItem>
                  <NavItem className={this.state.isOpen ? 'open' : ''}>
                    <NavLink to="/scan-qr-code" onClick={this.toggle}>Scan QR Code</NavLink>
                  </NavItem>
                  {!!currentUser?.flags?.['crud-admin'] && <NavItem className={this.state.isOpen ? 'open' : ''}>
                    <NavLink to="/accounts" onClick={this.toggle}>Admin</NavLink>
                  </NavItem>}
                  {!!currentUser?.flags?.['crud-admin-comp-sci'] && <NavItem className={this.state.isOpen ? 'open' : ''}>
                    <NavLink to="/indicators" onClick={this.toggle}>Admin</NavLink>
                  </NavItem>}
                </Fragment>
              }
            </Nav>
            <Nav navbar>
              {!this.props.isAuthenticated &&
                <Fragment>
                  <NavItem className={this.state.isOpen ? 'open' : ''}>
                    <NavLink to="/register" onClick={this.toggle}>Register</NavLink>
                  </NavItem>

                  <NavItem className={this.state.isOpen ? 'open' : ''}>
                    <NavLink to="/login" onClick={this.toggle}>Login</NavLink>
                  </NavItem>
                </Fragment>
              }
              {this.props.isAuthenticated &&
                <UncontrolledDropdown nav inNavbar className={this.state.isOpen ? 'open' : ''}>
                  <DropdownToggle nav caret >
                    {currentUser && currentUser.first_name ? currentUser.first_name : 'User'}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem tag="div">
                      <NavLink to="/my-account" onClick={this.toggle}>My Account</NavLink>
                    </DropdownItem>
                    <DropdownItem tag="div">
                    {/* TODO: reroute to current page, if page is non-authenticated */}
                      <NavLink
                        onClick={() => dispatch(logoutUser())}
                        to="/login">Logout</NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
            </Nav>
          </Collapse>
        </Navbar>
      </StyledNavBar>
    );
  }
}

NavBar.propTypes = {
  currentUser: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default NavBar;