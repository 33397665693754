import { createSelector } from 'reselect'

import {
  GET_ACCOUNT_FIELDS_REQUEST ,
  GET_ACCOUNT_FIELDS_FAILURE,
  GET_ACCOUNT_FIELDS_SUCCESS,

  GET_FIELD_REQUEST,
  GET_FIELD_SUCCESS,
  GET_FIELD_FAILURE,

  TRANSFER_FIELDS_REQUEST,
  TRANSFER_FIELDS_SUCCESS,
  TRANSFER_FIELDS_FAILURE,

} from './fields-actions'
import { getPermissionsForSelectedAccount } from './permissions-reducer'
import { appStore } from '../../index'


const initialState = {
  pending: false,
  pendingAccounts: new Set(),
  pendingFields: new Set(),
  hasError: false,
  error: null,
  fieldEntities: null,
  isTransferring: false,
}

export const fieldsReducer = (state = { ...initialState }, action) => {
  switch(action.type) {

    ////////////////////////////////
    // Get Account Fields Handlers
    ////////////////////////////////
    case GET_ACCOUNT_FIELDS_REQUEST: {
      return { ...state, pending: true, pendingAccounts: state.pendingAccounts.add(action.accountId) }
    }
    case GET_ACCOUNT_FIELDS_SUCCESS: {
      state.pendingAccounts.delete(action.accountId)
      const fieldEntities = {}

      action.payload.forEach(entity => {
        fieldEntities[entity.id] = entity
      })
      return {
        ...state,
        pending: !!state.pendingAccounts.size,
        hasError: false,
        fieldEntities: {
          ...state.fieldEntities,
          [action.accountId]: fieldEntities
        }
      }
    }
    case GET_ACCOUNT_FIELDS_FAILURE: {
      return {
        ...state,
        pending: false,
        hasError: true,
        error: action.error
      }
    }

    ////////////////////////////////
    // Get Fields Handler
    ////////////////////////////////
    case GET_FIELD_REQUEST: {
      return { ...state, pending: true, pendingFields: state.pendingFields.add(action.fieldId) }
    }
    case GET_FIELD_SUCCESS: {
      state.pendingFields.delete(action.fieldId)
      return {
        ...state,
        pending: !!state.pendingFields.size,
        hasError: false,
        fieldEntities: {
          ...state.fieldEntities,
          [action.payload.account_id]: {
            ...state.fieldEntities[action.payload.account_id],
            [action.fieldId]: action.payload
          }
        }
      }
    }
    case GET_FIELD_FAILURE: {
      state.pendingFields.delete(action.fieldId)
      return {
        ...state,
        pending: !!state.pendingFields.size,
        hasError: true,
        error: action.error
      }
    }

    ////////////////////////
    // Transfer Field Handlers
    ////////////////////////

    case TRANSFER_FIELDS_REQUEST: {
      return { ...state, isTransferring: true }
    }

    case TRANSFER_FIELDS_SUCCESS: {
      return {
        ...state,
        isTransferring: false,
        error: null
      }
    }

    case TRANSFER_FIELDS_FAILURE: {
      return {
        ...state,
        isTransferring: false,
        error: action.error
      }
    }

    default: {
      return state
    }
  }
}


//////////////////
// SELECTORS
//////////////////
const getSelectedAccountId = state => state.entities.users.selectedAccountId
const getFieldsByAccount = state => state.entities.fields.fieldEntities
const getAccountFields = (fields, acct) => {
  let allfields =  {...fields}
  return allfields[acct]
}

const getOwnAndPermissionedFields = (fields, acct) => {
  const store = appStore.getState()
  const selectedAccountPermissions = getPermissionsForSelectedAccount(store)
  // include user's own set of headless growers
  const permissionedAccounts = selectedAccountPermissions?.permissioned?.allIds || []
  const permissionedAccountsById = selectedAccountPermissions?.permissioned?.byId || {}
  const permissionedHeadlessAccounts = permissionedAccounts.filter(acctId => !permissionedAccountsById?.[acctId]?.user_details)

  let allfields =  {...fields}
  return [ ...permissionedHeadlessAccounts, acct ].reduce((acc, accountId) => ({ ...acc, ...allfields[accountId] }), {})
}

export const getOwnFields = createSelector(
  getFieldsByAccount,
  getAccountFields
)

export const getFieldsForSelectedAccount = createSelector(
  getFieldsByAccount,
  getSelectedAccountId,
  getAccountFields
)

export const getFieldsForSelectedAndPermissionedAccounts = createSelector(
  getFieldsByAccount,
  getSelectedAccountId,
  getOwnAndPermissionedFields
)
