import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './styles/index.css'

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { ConnectedRouter, routerMiddleware, routerReducer as router } from 'react-router-redux'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { reducer as form } from 'redux-form'
import { createBrowserHistory } from 'history'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import { Router, BrowserRouter, useNavigate } from 'react-router-dom'

import App from './App/App'
import FallbackComponent from './components/NotFound/ServerError'
import authReducer from './common/actions-reducers/auth-reducer'
import messagesReducer from './components/FlashMessages/messages-reducer'
import entitiesReducer from './App/entities'
import modalReducer from './components/Modal/modalReducer'
import accountsReducer from './common/actions-reducers/accounts-reducer'
import { urlReducer } from './common/actions-reducers/getUrl'
import { ordersReducer } from './common/actions-reducers/orders'
import viewReducer from './common/actions-reducers/view-reducer'
import metaReducer from './App/meta-reducer'
import { theme, muiTheme } from './common/utils/theme'
import { LOGOUT_SUCCESS } from './common/actions-reducers/auth-actions'
import { ThemeProvider as Muitheme } from '@material-ui/styles'



const history = createBrowserHistory()
const routeMiddleware = routerMiddleware(history)

let middleware = [thunkMiddleware,
  routeMiddleware]

// Set stage appropriately as Node_env will be set to production
// for any bundle created by 'build' react script
//eslin
let stage = process.env.REACT_APP_BASE_API_URL.includes('staging') ? 'staging' : // eslint-disable-line
            process.env.REACT_APP_BASE_API_URL.includes('demo') ? 'demo' : 'production' // eslint-disable-line

if (process.env.NODE_ENV !== 'production') { // eslint-disable-line
  const { createLogger } = require(`redux-logger`)
  const loggerMiddleware = createLogger()
  const lm = loggerMiddleware
  middleware = [...middleware, lm]
  stage = 'development'
}

// Allow access to unreleased for non-prod environments
export const allowUnreleased = stage !== 'production'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const appReducer = combineReducers({
  auth: authReducer,
  form,
  router,
  messagesReducer,
  entities: entitiesReducer,
  modalReducer,
  accountsReducer,
  viewReducer,
  urlReducer,
  ordersReducer,
  meta: metaReducer
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined
  }

  return appReducer(state, action)
}


function configureStore(preloadedState = {}) {
  return createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(...middleware)
    )
  )
}


export const appStore = configureStore()

// Every bugsnag report will be accompanied by user details
const updateUserDetails = report => {
  let authState = appStore.getState().auth
  report.user = {
    id: authState.user.account_id,
    name: `${authState.user.first_name} ${
      authState.user.last_name
    }`,
    email: authState.user.email,
    viewingUser: authState?.viewingUser?.id
  }
}
//configure client and reporting boundry
const bugsnagClient = bugsnag({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY || 'null', // eslint-disable-line
  releaseStage: stage,
  beforeSend: updateUserDetails,
})
bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = bugsnagClient.getPlugin('react')

render(
  <ErrorBoundary FallbackComponent={FallbackComponent}>
    <Provider store={appStore}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <Muitheme theme={muiTheme}>
            <BrowserRouter location={history.location} navigator={history}>
              <App bugs={bugsnagClient} />
            </BrowserRouter>
          </Muitheme>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
)

