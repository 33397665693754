import { createSelector } from 'reselect';

import {
  GET_ACCOUNT_FARMS_REQUEST ,
  GET_ACCOUNT_FARMS_FAILURE,
  GET_ACCOUNT_FARMS_SUCCESS,
} from './farms-actions';
import { getPermissionsForSelectedAccount } from './permissions-reducer'
import { appStore } from '../../index'


const initialState = {
  pending: false,
  hasError: false,
  error: null,
  farmEntities: {},
};

export const farmsReducer = (state = { ...initialState }, action) => {
  switch(action.type) {

    ////////////////////////
    // Get Handlers
    ////////////////////////
    case GET_ACCOUNT_FARMS_REQUEST: {
      return { ...state, pending: action.isFetching };
    }
    case GET_ACCOUNT_FARMS_SUCCESS: {
      const acctFarms = {};
      action.payload.forEach(entity => {
        acctFarms[entity.id] = entity;
      });
      return {
        ...state,
        pending: false,
        hasError: false,
        farmEntities: {
          ...state.farmEntities,
          [action.accountId]: acctFarms
        }
      };
    }
    case  GET_ACCOUNT_FARMS_FAILURE: {
      return {
        ...state,
        pending: false,
        hasError: true,
        error: action.error
      };
    }
    default: {
      return state;
    }
  }
}
//////////////////
// SELECTORS
//////////////////
const getSelectedAccountId = state => state.entities.users.selectedAccountId
const getFarmsByAccount = state => state.entities.farms.farmEntities
const getAccountFarms = (farms, acct) => {
  let allfarms =  {...farms}
  return allfarms[acct]
}

const getOwnAndPermissionedFarms = (farms, acct) => {
  const store = appStore.getState()
  const selectedAccountPermissions = getPermissionsForSelectedAccount(store)
  // include user's own set of headless growers
  const permissionedAccounts = selectedAccountPermissions?.permissioned?.allIds || []
  const permissionedAccountsById = selectedAccountPermissions?.permissioned?.byId || {}
  const permissionedHeadlessAccounts = permissionedAccounts.filter(acctId => !permissionedAccountsById?.[acctId]?.user_details)

  let allfarms =  {...farms}
  return [ ...permissionedHeadlessAccounts, acct ].reduce((acc, accountId) => ({ ...acc, ...allfarms[accountId] }), {})
}

export const getOwnFarms = createSelector(
  getFarmsByAccount,
  getAccountFarms
)

export const getFarmsForSelectedAccount = createSelector(
  getFarmsByAccount,
  getSelectedAccountId,
  getAccountFarms
)

export const getFarmsForSelectedAndPermissionedAccounts = createSelector(
  getFarmsByAccount,
  getSelectedAccountId,
  getOwnAndPermissionedFarms
)

