import {
  getBaseApiHelper,
} from '../../common/utils/apiHelpers';

export const GET_ACCOUNT_FARMS_REQUEST = 'GET_ACCOUNT_FARMS_REQUEST';
export const GET_ACCOUNT_FARMS_FAILURE = 'GET_ACCOUNT_FARMS_FAILURE';
export const GET_ACCOUNT_FARMS_SUCCESS = 'GET_ACCOUNT_FARMS_SUCCESS';

// Action Creators

const getAccountFarmsRequest = () => ({
  type: GET_ACCOUNT_FARMS_REQUEST,
  isFetching: true
});

const getAccountFarmsSuccess = (payload, accountId) => ({
  type: GET_ACCOUNT_FARMS_SUCCESS,
  payload,
  accountId
});

const getAccountFarmsFailure = payloadError => ({
  type: GET_ACCOUNT_FARMS_FAILURE,
  error: payloadError
});

export const getAccountFarms = (acctId) => {
  if (!acctId){
    return
  }
  return dispatch => {
    dispatch(getAccountFarmsRequest());
    return getBaseApiHelper(`/account/${acctId}/farms`)
      .then(response => {
        dispatch(getAccountFarmsSuccess(response.data, acctId));
      })
      .catch(rejected => {
        dispatch(getAccountFarmsFailure(rejected.response));
        return Promise.reject({ error: rejected.response });
      });
  };
}
