import {
  CONSTANTS_REQUEST,
  CONSTANTS_SUCCESS,
  CONSTANTS_FAILURE,
} from '../../common/actions-reducers/constants-actions';

// Initial State

const initialCropState = {
  isFetching: false,
  crops: []
};

// crops included in Constants api call so listen for that action
export function cropsReducer(state = { ...initialCropState }, action) {
  switch (action.type) {
    case CONSTANTS_REQUEST:
      return { ...state, isFetching: true };
    case CONSTANTS_SUCCESS:
      var crops = Object.keys(action.constants.crop_pathogens).sort()
      return {
        ...state,
        isFetching: false,
        crops,
      }
    case CONSTANTS_FAILURE:
      return { ...state, isFetching: false, errorMessage: action.error };
    default: {
      return state;
    }
  }
}

const initialIndicatorsState = {
  isFetching: false,
  shis: {},
  cropPathogens: {},
  pathogens: {},
  chemistryParameters: {},
  allByKey: {}
}

export function indicatorReducer(state = initialIndicatorsState, action) {
  switch (action.type) {
    case CONSTANTS_REQUEST:
      return { ...initialIndicatorsState, isFetching: true }

    case CONSTANTS_SUCCESS: {
      let pathogens = {}
      let allByKey = {
        shis: action.constants['shis'],
        chemistryParameters: action.constants['chemistry_parameters']
      }
      Object.entries(action['cropPathogens']).map(([crop, crop_pathogens]) => {
        !!crop_pathogens['pathogens'] && Object.entries(crop_pathogens['pathogens']).map(([pathKey, pathInfo]) => {
          if (!(pathKey in allByKey)) {
            allByKey[pathKey] = pathInfo
            pathogens[pathKey] = pathInfo
          }
        })
      })
      return {
        isFetching: false,
        shis: action.constants['shis'],
        chemistryParameters: action.constants['chemistry_parameters'],
        pathogens,
        allByKey
      }
    }
    case CONSTANTS_FAILURE:
      return { ...state, isFetching: false, errorMessage: action.error };

    default: {
      return state
    }
  }
}

export function getAllCrops(store) {
  return store.entities.crops.crops;
}

export function getCropsIsFetching(store) {
  return store.entities.crops.isFetching;
}
