import {
  GET_PERMISSIONS_REQUEST ,
  GET_PERMISSIONS_FAILURE,
  GET_PERMISSIONS_SUCCESS,

} from './permissions-actions';
import { createSelector } from 'reselect'


// Internal Structure
//
// permissionStructure= {
//   pending: false,
//   hasError: false,
//   error: null,
//   permissioned: {
//     allAcctIds: [],
//     byAcctId: {}
//   },
//   owned: {
//     allAcctIds: [],
//     byAcctId: {}
//   }
// }


const initialState = {
};

export const permissionsReducer = (state = { ...initialState }, action) => {
  switch(action.type) {

    case GET_PERMISSIONS_REQUEST: {
      return {
        ...state,
        [action.acctId]: {
          ...state[action.acctId],
          pending: action.isFetching,
          hasError: false,
          error: null
        }
      };
    }
    case GET_PERMISSIONS_SUCCESS: {
      const permissioned = {
        byId: action.payload.permissioned.reduce((memo,el)=>(memo[el.owning_account_id] = el) && memo, {}),
        allIds: action.payload.permissioned.map(el=>el.owning_account_id)
      }
      const owned = {
        byId: action.payload.owned.reduce((memo,el)=>(memo[el.permissioned_account_id] = el) && memo, {}),
        allIds: action.payload.owned.map(el=>el.permissioned_account_id)
      }
      return {
        ...state,
        [action.acctId]: {
          ...state[action.acctId],
          pending: false,
          hasError: false,
          permissioned,
          owned
        }
      }
    }
    case GET_PERMISSIONS_FAILURE: {
      return {
        ...state,
        [action.acctId]: {
          ...state[action.acctId],
          pending: false,
          hasError: true,
          error: action.error
        }
      };
    }

    default: {
      return state;
    }
  }
}



const getPermissionsById = state=>state.entities.permissions.permissionedAccounts.byId

const swapPermissionKey = byId => {
  return Object.fromEntries(Object.values(byId).map(acct=>[acct.owning_account_id, acct]))
}
export const permissionsByAccount = createSelector(
  [getPermissionsById],
  swapPermissionKey
)


//////////////////
// SELECTORS
//////////////////
const getSelectedAccountId = state => state.entities.users.selectedAccountId
const getPermissionsByAccount = state => state.entities.permissions
const getAccountPermissions = (permissions, acct) => {
  return permissions[acct]
}

export const getPermissionsForSelectedAccount = createSelector(
  getPermissionsByAccount,
  getSelectedAccountId,
  getAccountPermissions
)
