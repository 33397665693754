import axios from 'axios'

// ACTIONS
const GET_URL_REQUEST = 'GET_URL_REQUEST'
const GET_URL_SUCCESS = 'GET_URL_SUCCESS'
const GET_URL_FAILURE = 'GET_URL_FAILURE'
const RESET_URL = 'RESET_URL'

const getUrlRequest = () => ({type: GET_URL_REQUEST})
const getUrlSuccess = data => ({
  type: GET_URL_SUCCESS,
  data
})
const getUrlFailure = error => ({
  type: GET_URL_FAILURE,
  error
})

export const getUrl = url => {
  return dispatch => {
    dispatch(getUrlRequest())
    return axios.get(url, {headers: {'Content-Type': `application/xml`}}).then(resp => {
      dispatch(getUrlSuccess(resp.data))
      return resp.data
    }).catch(error => {
      dispatch(getUrlFailure(error.response))
      return Promise.reject(error.response)
    })
  }
}

export const resetUrl = () => ({type: RESET_URL})

// REDUCER
const initialState = {
  data: null,
  isFetching: false,
  error: null
}

export const urlReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_URL_REQUEST: {
      return {...state, isFetching: true}
    }
    case GET_URL_SUCCESS: {
      return {...state, isFetching: false, data: action.data}
    }
    case GET_URL_FAILURE: {
      return {...state, isFetching: false, error: action.error}
    }
    case RESET_URL: {
      return { ...initialState }
    }
    default: {
      return { ...state }
    }
  }
}
