import { ADD_FLASH_MESSAGE, REMOVE_FLASH_MESSAGE } from './messages-actions';


// Initial AuthReducer State
const initialState = {
  messages: []
};

// Auth Reducer
function messagesReducer(state = initialState, action) {
  switch(action.type) {
    case ADD_FLASH_MESSAGE:
      return Object.assign({}, state, {
        messages: [...state.messages, action.message]
      });
    case REMOVE_FLASH_MESSAGE: {
      return Object.assign({}, state, {
        messages: state.messages.filter((f) => action.messageId !== f.id)
      });
    }
    default: {
      return state;
    }
  }
}

export default messagesReducer;
