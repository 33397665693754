import { combineReducers } from 'redux'
import sampleSearchReducer from '../components/SampleSearch/sample-reducer'
import userSearchReducer from '../common/actions-reducers/user-search-reducer'
import { cropsReducer, indicatorReducer } from '../components/SampleSearch/crop-reducer'
import { permissionsReducer } from '../common/actions-reducers/permissions-reducer'
import { farmsReducer }  from '../common/actions-reducers/farms-reducer'
import { fieldsReducer } from '../common/actions-reducers/fields-reducer'
import { accountOrdersReducer } from '../common/actions-reducers/account-orders'
import { productsReducer, analysisProductsReducer } from '../common/actions-reducers/products'

const entitiesReducer = combineReducers({
  samples: sampleSearchReducer,
  users: userSearchReducer,
  crops: cropsReducer,
  indicators: indicatorReducer,
  permissions: permissionsReducer,
  farms: farmsReducer,
  fields: fieldsReducer,
  orders: accountOrdersReducer,
  products: productsReducer,
  analysisProducts: analysisProductsReducer
})

export default entitiesReducer
