export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'

export function setActiveTab(tab) {
  return {
    type: SET_ACTIVE_TAB,
    activeTab: tab
  }
} 

const initialState = {
  activeTab: null
}

function viewReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return Object.assign({}, state, {
        activeTab: action.activeTab
      })
    default:
      return state;
  }
}

export default viewReducer;
