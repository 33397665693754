import { TOGGLE_UNRELEASED_ACCESS, SET_BETA_ACCESS } from './meta-actions';

const initialState = {
  unreleasedVisible: false,
  betaVisible: false
};

function metaReducer(state = initialState, action) {

  switch (action.type) {
    case TOGGLE_UNRELEASED_ACCESS:
      return {
        ...state,
        unreleasedVisible: !state.unreleasedVisible
      }
    case SET_BETA_ACCESS:
      return {
        ...state,
        betaVisible: action.access
      }

    default: return state
  }
}

export default metaReducer
