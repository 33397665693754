import styled from 'styled-components';

export const StyledNavBar = styled.div`
	.navbar{
		background-color: white;
		width: 100%;
		z-index: 2;
		box-shadow: 0 2px 8px #dddddd;
		position: inherit;
    padding: 5px 5%;
    font-size: 14px; 
    font-weight: 500;
	}
	.navbar .dropdown{
		&.nav-item{
			padding-bottom: 10px;
		}	
	}

  .navbar-brand{
    img{
      width: 120px;
      height: auto;
    }
  }

	.navbar-nav{
		background-color: #ffffff;

		.dropdown-item, .nav-item{
			a {
		    text-decoration: none;
		    color: #6d6e71;
		    padding: .5rem;
		    display: block;
		    background-color: transparent;
			}
		
			&:active {
				text-decoration: none;
				background-color: #f8f9fa;
				outline: none;
			}
		}
	}
	.navbar-light .navbar-nav .show>.nav-link {
		color: #6c757d;
	}

  @media screen and (min-width: 768px){
    .navbar{
      padding: 5px 60px;
    }
  }

	@media screen and (min-width: 992px){
		// defining navbar height for hover effects
		.navbar{
			height: 66px;
			background-color: #ffffff;
      padding: 0 60px;
			.dropdown{
				&.nav-item{
					padding-bottom: 0;
				}	
			}
		}
		.navbar-nav{
			// parent of navbar-collapse bellow
			height: 100%;
			margin-left: 35px;
		}

		.navbar-collapse {
			// allows for hover effect to cover top to bottom of navbar
			height: 100% !important;

			.nav-item {
				padding: 0 16px;
				height: 100%;
				align-items: center;
				display: flex;
				transition: background-color 0.3s ease-in-out;

				&:hover {
					background-color: #f8f9fa;
				}
			}
		}
	}
`;
