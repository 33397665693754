import {
  FETCH_ACCOUNTS_REQUEST,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNTS_FAILURE,
  GET_ACCOUNT_REQUEST,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAILURE
} from './user-search-actions'

const initialState = {
  isFetching: false,
  byId: null,
  allIds: null,
  error: null,
  selectedAccount: null
}

function accountsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case FETCH_ACCOUNTS_SUCCESS: {
      const nameWithEmail = acct => {
        const email = acct.users?.[0]?.email || ''
        const emailStr = acct.name && email ? `(${email})` : email
        return `${acct.name} ${emailStr}`
      }
      return Object.assign({}, state, {
        isFetching: false,
        byId: action.accounts.reduce((obj, acct) => {
          return Object.assign({}, obj, {[acct.id]: { ...acct, nameWithEmail: nameWithEmail(acct) }})
        }, {}),
        allIds: action.accounts.map(acct => acct.id)
      })
    }
    case FETCH_ACCOUNTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      })
    case GET_ACCOUNT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        selectedAccount: null
      })
    case GET_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        selectedAccount: action.account
      })
    case GET_ACCOUNT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      })
    default:
      return state;
  }
}

export default accountsReducer;
