import React from 'react';
import corn from '../../assets/images/trace_corn.png';
import { StyledNotFoundPage } from './styles';
import PropTypes from 'prop-types';

const ServerError = (props) => {
	return(
		<StyledNotFoundPage>
				<div height="60px">&nbsp;</div>
				<img src={corn} alt="Trace Genomics 500 Corn"/>
				<h1>500</h1>
				<h3>Sorry, the page you tried cannot be found.</h3>
				<p>Looks like a bad request has resulted in an error.</p>

				<button onClick={props.goBack}>Go Back</button>
		
		</StyledNotFoundPage>
	);
};

ServerError.propTypes = {
  goBack : PropTypes.func.isRequired
};

export default ServerError;
