import {
  getBaseApiHelper,
  postBaseApiHelper,
} from '../../common/utils/apiHelpers.js'

import { addFlashMessage } from '../../components/FlashMessages/messages-actions.js'
import { getAccountFarms } from './farms-actions'
import { fetchSamplesForAccount } from '../../components/SampleSearch/sample-actions'
export const GET_ACCOUNT_FIELDS_REQUEST = 'GET_ACCOUNT_FIELDS_REQUEST'
export const GET_ACCOUNT_FIELDS_FAILURE = 'GET_ACCOUNT_FIELDS_FAILURE'
export const GET_ACCOUNT_FIELDS_SUCCESS = 'GET_ACCOUNT_FIELDS_SUCCESS'

export const GET_FIELD_REQUEST = 'GET_FIELD_REQUEST'
export const GET_FIELD_FAILURE = 'GET_FIELD_FAILURE'
export const GET_FIELD_SUCCESS = 'GET_FIELD_SUCCESS'

export const TRANSFER_FIELDS_REQUEST = 'TRANSFER_FIELDS_REQUEST'
export const TRANSFER_FIELDS_SUCCESS = 'TRANSFER_FIELDS_SUCCESS'
export const TRANSFER_FIELDS_FAILURE = 'TRANSFER_FIELDS_FAILURE'

// Action Creators

/*
* Get fields by account ID
*/
const getAccountFieldsRequest = (accountId) => ({
  type: GET_ACCOUNT_FIELDS_REQUEST,
  accountId
})

const getAccountFieldsSuccess = (payload, accountId) => ({
  type: GET_ACCOUNT_FIELDS_SUCCESS,
  payload,
  accountId
})

const getAccountFieldsFailure = payloadError => ({
  type: GET_ACCOUNT_FIELDS_FAILURE,
  error: payloadError
})

export const getAccountFields = (accountId) => {
  return dispatch => {
    dispatch(getAccountFieldsRequest(accountId))
    return getBaseApiHelper(`/account/${accountId}/fields`)
      .then(response => {
        dispatch(getAccountFieldsSuccess(response.data, accountId))
        return response
      })
      .catch(rejected => {
        dispatch(getAccountFieldsFailure(rejected.response))
        return Promise.reject({ error: rejected.response })
      })
  }
}

/*
* Get field by ID
*/
const getFieldRequest = (fieldId) => ({
  type: GET_FIELD_REQUEST,
  fieldId
})

const getFieldSuccess = (payload, fieldId) => ({
  type: GET_FIELD_SUCCESS,
  payload,
  fieldId
})

const getFieldFailure = (payloadError, fieldId) => ({
  type: GET_FIELD_FAILURE,
  error: payloadError,
  fieldId
})

export const getField = (fieldId) => {
  return dispatch => {
    dispatch(getFieldRequest(fieldId))
    return getBaseApiHelper(`/fields/${fieldId}`)
      .then(response => {
        dispatch(getFieldSuccess(response.data, fieldId))
        return response
      })
      .catch(rejected => {
        dispatch(getFieldFailure(rejected.response, fieldId))
        return Promise.reject({ error: rejected.response })
      })
  }
}


/*
* Transfer fields
*/
const transferFieldsRequest = () => ({
  type: 'TRANSFER_FIELDS_REQUEST'
})

const transferFieldsSuccess = () => ({
  type: 'TRANSFER_FIELDS_SUCCESS',
})

const transferFieldsFailure = (errorPayload) => ({
  type: 'TRANSFER_FIELDS_FAILURE',
  error: errorPayload
})
export const transferFields = (fieldIds, account_to, account_from) => {
  return dispatch => {
    dispatch(transferFieldsRequest())
    return postBaseApiHelper(
      `/fields/transfer`,
      {
        fields: fieldIds,
        account_from,
        account_to
      })
      .then(resp=>{
        // TODO add snack bar component with controlled open and close
        dispatch(transferFieldsSuccess())
        dispatch(addFlashMessage({
          title: 'Syncing Accounts',
          text: '',
          type: 'success'
        }))
        Promise.all([
          //All these items can be changed by the transfer action
          dispatch(getAccountFields(account_to)),
          dispatch(getAccountFarms(account_to)),
          dispatch(fetchSamplesForAccount(account_to)),
          dispatch(getAccountFields(account_from)),
          dispatch(getAccountFarms(account_from)),
          dispatch(fetchSamplesForAccount(account_from))
        ]).then(resp=>dispatch(addFlashMessage({
          title: 'Sync Complete',
          text: '',
          type: 'success'
        }))).catch(resp=>dispatch(addFlashMessage({
          title: 'Sync Failed',
          text: '',
          type: 'danger'
        })))
        return resp
      })
      .catch(rejected=>{
        dispatch(transferFieldsFailure(rejected?.response))
        dispatch(addFlashMessage({
          title: 'Transfer Failed',
          text: '',
          type: 'danger'
        }))
        return Promise.reject(rejected?.response)
      })
  }
}
