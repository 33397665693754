
/*******************
 DATE HELPERS
********************/

/**
 * @param {string} utcDateString A string of format parsable by MDN Date constructor
 * @returns {string} A string in format M/D/YY
 *
 * example input, output:
 * In: '2018-01-29T09:00:00+00:00'
 * Out: '1/29/18'
 */

export const utcToUSDate = (utcDateString) => {
  if (!utcDateString) return null
  let date = new Date(utcDateString)
  let dateOptions = {month: 'numeric', day: 'numeric', year: '2-digit'}
  let usDate = date.toLocaleDateString('en-US', dateOptions)
  return usDate
}

/**
 * @param {string} date A year-first skewer case date (YYYY-MM-DD)
 * @returns {string} A string in format MM-DD-YY
 */

export const yearFirstSkewerCaseToUS = (date) => {
  if (!date) return ''
  const dateArray = date.split('-')
  return `${dateArray[1]}/${dateArray[2]}/${dateArray[0].slice(2)}`
}
/**
 *
 * @returns {string} A string in format MM-DD-YY that is today's date
 */
export const getToday = () => {
  let date = new Date()
  let dateOptions = { month: 'numeric', day: 'numeric', year: '2-digit' }
  let usDate = date.toLocaleDateString('en-US', dateOptions)
  return usDate
}

/**
 *
 * @returns {number} The number of days between two dates
 */
export const getDaysDiff = (date1, date2) => {
  const secondsPassed = Math.abs(date2 - date1) / 1000
  return Math.floor(secondsPassed / (60*60*24))
}


/*******************
 STRING HELPERS
********************/


/**
 * @param {string} snakeString A snake case string
 * @returns {string} string converted to Title case
 *
 * example input, output:
 * In: 'hello_world_foObAr'
 * Out: 'Hello World Foobar'
 */
export const snakeToTitleCase = snakeString => snakeString.split('_').map(string => string.slice(0,1).toUpperCase() + string.slice(1).toLowerCase()).join(' ')


/**
 * @param {string} string A mixed case string
 * @returns {string} string converted to sentence case
 *
 * example input, output:
 * In: 'ThiS Is BaD'
 * Out: 'This is bad'
 */
export const toSentenceCase = (string) => (string && string.slice(0,1).toUpperCase() + string.slice(1).toLowerCase())

export const camelCaseToSentenceCase = string => toSentenceCase(string.split(/(?=[A-Z])/).join(' '))
/**
 * @param {string} string A string
 * @returns {string} string converted to all lower case with whitespace trimmed and
 * multiple spaces converted to single spaces.
 *
 * example input, output:
 * In: 'ThiS Is    BaD  '
 * Out: 'this is bad'
 */
export const normalizeText = (badText='') => badText.toLowerCase().trim().replace(/\s+/g, ' ')

export const areEqual = (textA, textB) => normalizeText(textA) === normalizeText(textB)

/**
 * @param {array} array of strings
 * @param {string} string to represent all entities
 * @param {string} string to separate parts of the key
 * @returns {string} string to represent a unique key
 *
 * example input, output:
 * In: ['tEsT', ' traceGENOMICS.com ']
 * Out: 'test::tracegenomics.com'
 */
export const makeKey = (arr, allKey='ALL', separator='::') => {
  const key = arr.map((str='') => normalizeText(str)).join(separator)
  return key === separator ? allKey : key
}
