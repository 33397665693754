import styled from 'styled-components';

export const StyledFooter = styled.footer`
	background-color: white;
	width: 100%;
  z-index: 2;
  box-shadow: 0 2px 8px #dddddd;
  padding: 20px 5%;
  display: none;
  flex-flow: column;
  align-items: center;
  
  .col{
    padding: 0
  }

  a, h4 {
    text-decoration: none;
    color: #6d6e71;
    display: block;
    font-size: 12px;
    font-weight: 700;
    margin: 0;
    line-height: 14px;
    padding: 10px 0; 
	}

  h4{
    text-align: center;
  }

  a{
    text-align: right;

    &:hover{
      color: #6d6e71;
    }
  }

	&:active {
		text-decoration: none;
		background-color: #f8f9fa;
		outline: none;
	}

  .link-group{
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
  }

  .support{
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-bottom: 0px;

    a{
      font-weight: 400;
      padding: 5px;
    }
  }

  @media screen and (min-width: 992px){
    height: 80px;
    padding: 20px 60px;
    flex-flow: row;

    h4{
      text-align: left;
    }

    a, h4{
      padding: 0;
    }

    .link-group{
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
      .link-group-item {
        min-width: 150px;
        max-width: 250px;
        a {
          text-align: center;
        }
      }
    
    }

    .support{
      padding: 0;
      text-align: center;
      align-items: flex-end;
      a{
        padding: 0;
        line-height: 15px;
      }
    }
  }
  
  &.authenticated{
    display: flex;
  }

`;
