import { createSelectorCreator, defaultMemoize } from 'reselect'
import _ from 'lodash'


export const memoize = (func)=>{
  const returnValues = {}
  return (...params) => {
    let paramString = params.reduce((memo, param)=> memo+param.toString(), '')
    if (paramString in returnValues){
      return returnValues[paramString]
    } else {
      let funcValue = func.apply(null,params)
      returnValues[paramString] = funcValue
      return funcValue;
    }
  }
}
export const createDeepSelector = createSelectorCreator(
  defaultMemoize,
  _.isEqual
)
