import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAILURE,
} from './auth-actions';

import { UPDATE_USER_SUCCESS } from '../../components/SampleSearch/user-search-actions';


// Initial AuthReducer State
const initialState = {
  isFetching: false,
  // if authToken has been generated, user has logged in and verified their email
  isAuthenticated: sessionStorage.getItem('authToken') ? true : false,
  user: JSON.parse(sessionStorage.getItem('user')) || {},
  errorMessage: '',
};

// Auth Reducer
function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated,
        errorMessage: '',
        authToken: action.authToken,
        user: action.user
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated,
        errorMessage: action.message,
        authToken: undefined,
        user: {}
      });
    case REGISTER_REQUEST:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
      });
    case REGISTER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
      });
    case REGISTER_FAILURE:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        user: {}
      });
    case USER_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case USER_SUCCESS: {
      return Object.assign({}, state, {
        isFetching: false,
        user: action.user
      });
    }
    case USER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.error
      });
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        user: state.user.id === action.user.id ? action.user : state.user
      };
    }
    default:
      return state;
  }
}



export default authReducer;
