import React from 'react';
import { NavLink } from 'react-router-dom';
import { StyledFooter } from './styles';
import PropTypes from 'prop-types';

const Footer = (props) => {
  let d = new Date();
  let todaysYear = d.getFullYear();

  return (
    <StyledFooter className={(props.isAuthenticated ? ' authenticated' : '')}>
      <div className="col-sm-8 col link-group">
        <h4>
          &copy; {todaysYear} Trace Genomics, Inc
        </h4>
        <div className="link-group-item">
          <NavLink
            to="/my-account/terms-of-agreement">
            Terms of Use
          </NavLink>
        </div>
        <div className="link-group-item">
          <NavLink to="/my-account/privacy">
            Privacy Policy
          </NavLink>
        </div>
      </div>

      <div className="col-sm-4 col">
        
        
        <div className="support">  
          <h4>Customer Support</h4>
          <a href="tel:+1-650-332-6661">+1 650-332-6661</a>
          <a href="mailto:support@tracegenomics.com">support@tracegenomics.com</a>
        </div>
      </div>
    </StyledFooter>
  );
};

Footer.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default Footer;
