import { isCancel, CancelToken } from 'axios'

import {
  getBaseApiHelper,
  deleteBaseApiHelper,
  buildQueryString
} from '../../common/utils/apiHelpers'

export const FETCH_SAMPLES_REQUEST = 'FETCH_SAMPLES_REQUEST'
export const FETCH_SAMPLES_SUCCESS = 'FETCH_SAMPLES_SUCCESS'
export const FETCH_SAMPLES_FAILURE = 'FETCH_SAMPLES_FAILURE'
export const FETCH_SAMPLES_FOR_ACCT_CANCELED = 'FETCH_SAMPLES_FOR_ACCT_CANCELED'
export const FETCH_SAMPLES_FOR_ACCT_SUCCESS = 'FETCH_SAMPLES_FOR_ACCT_SUCCESS'
export const SELECT_SAMPLE_FOR_DETAIL = 'SELECT_SAMPLE_FOR_DETAIL'

export const SET_FILTERED_IDS = 'SET_FILTERED_IDS'

export const DELETE_SAMPLE_REQUEST = 'DELETE_SAMPLE_REQUEST'
export const DELETE_SAMPLE_SUCCESS = 'DELETE_SAMPLE_SUCCESS'
export const DELETE_SAMPLE_FAILURE = 'DELETE_SAMPLE_FAILURE'


// Action Creators
export function selectSampleForDetail(tubeId) {
  return {
    type: SELECT_SAMPLE_FOR_DETAIL,
    tubeId
  }
}

export const setFilteredIds = ids => ({
  type: SET_FILTERED_IDS,
  ids
})

function samplesRequest(acctId) {
  return {
    type: FETCH_SAMPLES_REQUEST,
    isFetching: true,
    acctId
  }
}

function fetchSamplesSuccess(response) {
  return {
    type: FETCH_SAMPLES_SUCCESS,
    samples: Array.isArray(response) ? response : response.results,
    page: Array.isArray(response) ? {} : response.meta,
  }}

function samplesForAccountSuccess(acctId, samples) {
  return {
    type: FETCH_SAMPLES_FOR_ACCT_SUCCESS,
    samples,
    acctId
  }
}

function samplesForAccountCanceled() {
  return {
    type: FETCH_SAMPLES_FOR_ACCT_CANCELED
  }
}

function samplesFailure(error) {
  return {
    type: FETCH_SAMPLES_FAILURE,
    error
  }
}


let cancelFetchSamplesForAccount
export function fetchSamplesForAccount(accountId, withValues = false) {
  let endpoint = accountId ? `/account/${accountId}/samples` : '/samples'
  endpoint += withValues ? '?include_values=true' : ''
  return dispatch => {
    if (cancelFetchSamplesForAccount) {
      cancelFetchSamplesForAccount()
      dispatch(samplesForAccountCanceled())
    }
    dispatch(samplesRequest(accountId))
    return getBaseApiHelper(endpoint, null, {
      cancelToken: new CancelToken(c => { cancelFetchSamplesForAccount = c })
    })
      .then(response => {
        dispatch(samplesForAccountSuccess(accountId, response.data))
        return response.data
      })
      .catch(error => {
        if (!isCancel(error)) {
          dispatch(samplesFailure(error))
        }
        return error
      })
  }
}

export function fetchSamples(data = {}) {
  return dispatch => {
    dispatch(samplesRequest())
    // if the data object contains the attribute "page", results will paginate
    // if the attr "page" is excluded, the endpoint will return the full set and
    //  nothing will retrigger recursively
    return getBaseApiHelper('/samples?' + buildQueryString(data))
      .then(response => {
        dispatch(fetchSamplesSuccess(response.data))
        // If next_url, recursively make API calls to fetch all data
        // This is a hack to not implement infinite scroll ATM.
        if (!!response.data.meta && !!response.data.meta['next_url']) {
          let new_data = Object.assign({}, data)
          new_data['page'] = response.data.meta['page'] + 1
          dispatch(fetchSamples(new_data))
        }
        return response.data
      })
      .catch(error => {
        dispatch(samplesFailure(error))
      })
  }
}

function deleteSampleRequest() {
  return {
    type: DELETE_SAMPLE_REQUEST,
    isPosting: true,
  }
}

function deleteSampleSuccess(sampleId) {
  return {
    type: DELETE_SAMPLE_SUCCESS,
    sampleId
  }
}

function deleteSampleFailure(error) {
  return {
    type: DELETE_SAMPLE_FAILURE,
    isPosting: false,
    error
  }
}

export function deleteSample(sampleId) {
  return dispatch => {
    dispatch(deleteSampleRequest())
    return deleteBaseApiHelper(`/samples/${sampleId}`)
      .then(response => {
        dispatch(deleteSampleSuccess(sampleId, response.data))
        return response
      })
      .catch(rejected => {
        if(rejected.response.status === 404) {
          dispatch(deleteSampleSuccess(sampleId, rejected.response))
          return rejected.response.data
        } else {
          dispatch(deleteSampleFailure(rejected.response))
          return Promise.reject(rejected.response)
        }
      })
  }
}



