import styled from 'styled-components';


export const StyledContainer = styled.div`
  font-family: Avenir;
  display: flex;
  justify-content: center;
  align-items: center;
	width: 100%;
  min-height: calc(100vh - (50px + 224px)); //navbar + footer

  @media screen and (min-width: 768px){
    .isAuthenticated {
			padding: 60px;
		}
  }

  @media screen and (min-width: 992px){
    min-height: calc(100vh - (66px + 80px)); //navbar + footer
	}

	.page-title{
		h1, h2, h3{
			font-family: Avenir Heavy;
			font-size: 28px;
			color: #6d6e71;
			margin-bottom: 20px;
			cursor: default;
		}
	}
	.auth-pages{
		width: 100%;
		height: 100%;

	}

  &.authenticated{
		padding: 15px 30px;
    align-items: flex-start;
  }
`;
